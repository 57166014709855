<template>
  <Home />
</template>

<script>
import Home from "@/components/Home";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Home,
  },
  metaInfo: {
    title: "Welkom bij Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "description",
    //     content: "Integratie partners Nederland ",
    //   },
    //   { name: "viewport", content: "width=device-width, initial-scale=1" },
    // ],
  },
};
</script>
