<template>
  <div class="content">
    <div class="leftsection">
      <div class="home-img home-image">
        <img src="@/assets/home/bg-search-country.jpg" />
        <p class="overlay-text">
          Zoek postzegel<br />
          op land<br />
          <v-autocomplete
            dense
            solo
            placeholder="kies een land"
            v-model="selectedCountry"
            :items="kavelsLanden"
            item-text="text"
            item-value="value"
            return-object
            @change="navigateToPartijen"
          ></v-autocomplete>

          Koop online, per e-mail<br />
          of bel ons. Tel: 070 362 52 63
        </p>
      </div>

      <div class="home-img">
        <img src="@/assets/home/img-inkoop.gif" />
      </div>
      <div class="home-img">
        <img src="@/assets/home/img-garantie.gif" />
      </div>
    </div>

    <div class="rightsection" style="padding-top:1rem">
      <v-row style="margin-bottom:10px">
        <div class="home-slider">
          <v-carousel
            cycle
            height="400"
            hide-delimiter-background
            :show-arrows="false"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              class="carousel-background-item"
              :style="{ backgroundImage: 'url(' + item.src + ')' }"
            >
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-row>
      <v-row style="margin-bottom:10px">
        <div class="home-text">
          <h3>Postzegels - inkoop en verkoop</h3>

          <p>
            Postzegels.com is de website van de Postzegel Partijen Centrale en
            is sinds de oprichting in 1994 zowel <br />

            in Nederland als ver daarbuiten een begrip geworden. Wilt u uw
            postzegels verkopen of postzegels kopen dan <br />
            bent u bij postzegels.com op het juiste adres. Wij beschouwen onze
            klantenkring als één grote familie en <br />
            zullen er alles aan doen om het ook u naar de zin te maken. Service
            en betrouwbaarheid zijn de basis van ons <br />
            succes en daar zijn we ons terdege van bewust. Postzegels.com is de
            plek voor in- en verkoop van postzegels. <br />
            Bel of e-mail ons gerust als u nog vragen heeft.
          </p>
        </div>
      </v-row>
      <v-row class="home-text" style="margin-bottom:10px;">
        <v-col cols="12" sm="6">
          <h3>Meest recente partij</h3>

          <p>
            <b> Kavelnummer: </b>{{ this.kavel.kavelnr }} <br />
            <b> Land: </b>{{ this.kavel.land.Ned }} <br />
            <b> Inhoud: </b>{{ this.kavel.inhoudNL }}<br />
            <b> Emballage: </b>{{ this.kavel.embNed }} <br />

            <b> Beschrijving: </b>{{ this.kavel.txtNed }} <br />
            <b> Prijs: </b>€ {{ this.kavel.price }}<br />
            <v-btn color="primary" @click="goToRecentKavel()">
              Kavel bekijken</v-btn
            >
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <h3>Koop van de dag</h3>
          <p>
            <b>Kavelnummer: </b> {{ sale.kavelnr }}<br />
            <b> Land: </b>{{ sale.land.Ned }} <br />
            <b> Inhoud: </b> {{ sale.inhoudNL }}<br />
            <b>Emballage: </b> {{ sale.embNed }}<br />

            <b>Beschrijving: </b>{{ sale.txtNed }}<br />
            <b> Prijs: </b>€ {{ sale.promotionPrice }}
            <span class="strike">€ {{ sale.price }}</span
            ><br />
            <v-btn color="primary" @click="goToSaleKavel()">
              Kavel bekijken</v-btn
            >
          </p>
        </v-col>
      </v-row>

      <v-row class="home-text">
        <v-col cols="12" sm="6">
          <h3>Nieuwsflash</h3>

          <p>
            Blijf op de hoogte. Neem nu een gratis abonnement op onze e-mail
            nieuwsflash!
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          Bestel 24 uur per dag op de website of kom gezellig langs in Den Haag!
          <br />

          <a
            href="https://www.google.com/maps/dir/52.0257536,4.6497792/Postzegel+Partijen+Centrale,+Piet+Heinstraat+112,+2518+CM+Den+Haag/@52.0477695,4.1692334,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47c5b7f99c5103d3:0x7c1ec60b2b53a374!2m2!1d4.2970592!2d52.0810759?entry=ttu"
            >ROUTEBESCHRIJVING
          </a>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import apiKavel from "../components/apiKavel";
export default {
  data() {
    return {
      sale: {},
      selectedCountry: null,
      kavelsLanden: [],
      kavel: [],
      items: [
        { src: require("../assets/home/banner1.jpg"), alt: "Banner 1" },
        { src: require("../assets/home/banner2.jpg"), alt: "Banner 2" },
        { src: require("../assets/home/banner3.jpg"), alt: "Banner 3" },
        // Andere afbeeldingen...
      ],
    };
  },
  methods: {
    goToRecentKavel() {
      this.$router.push(`/Details/${this.kavel.kavelnr}`);
    },
    goToSaleKavel() {
      this.$router.push(`/Details/${this.sale.kavelnr}`);
    },
    navigateToPartijen() {
      if (this.selectedCountry && this.selectedCountry.text) {
        const countryName = this.selectedCountry.text.split(" (")[0];
        this.$router.push({ name: "Partijen", query: { land: countryName } });
      }
    },
  },
  mounted() {
    apiKavel.getLastAddedKavel().then((data) => {
      this.kavel = data;
    });
    apiKavel.getSaleKavel().then((data) => {
      this.sale = data;
    });
    apiKavel.getLandenMetAantalKavels().then((data) => {
      this.kavelsLanden = data.map((land) => ({
        text: `${land.Ned} (${land.aantalKavels} kavels)`,
        value: land.id,
      }));
    });
  },
};
</script>

<style scoped>
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.rightsection {
  float: right;
  width: 690px;
  overflow: hidden;
}
.leftsection {
  float: left;
  width: 240px;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
}

.home-text h3 {
  margin-left: 25px;
}

.home-text p {
  margin-left: 25px;
}
.home-text {
  color: #000033;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #c8d4e4;
  color: #000033;
  width: 100%;
}

.home-text h3 {
  font-size: 19px;
  color: #000033;
  font-weight: bold;
}
.home-img img {
  width: 100%; /* Zorgt ervoor dat de afbeelding de volledige breedte van zijn container inneemt */
  height: auto; /* Behoudt de aspect ratio van de afbeelding */
}

.home-image {
  position: relative;
}

.overlay-text {
  font-size: 20px;
  width: 100%;
  position: absolute;

  top: 56%;
  left: 82%;
  transform: translate(-80%, -80%);
  color: white;
  font-weight: bold;
  z-index: 100;
}
.column-with-margin {
  margin: 2px;
}
.home-slider {
  position: relative;
  width: 100%;
  height: 215px; /* Pas de hoogte naar wens aan */
  overflow: hidden;
}

.home-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.home-slider img.active {
  opacity: 1;
}

.content {
  width: 940px;
  margin: 0 auto;
  margin-top: 0px;
  margin-top: 10px;
  min-height: 300px;
}

.strike {
  text-decoration: line-through;
}
</style>
<style scoped>
/* Algemene stijlen blijven hetzelfde */

/* Responsive aanpassingen */
@media (max-width: 1024px) {
  .rightsection,
  .leftsection {
    float: none;
    width: auto; /* Maak de breedte automatisch voor kleinere schermen */
    margin: 0 auto; /* Centreer de secties */
  }

  .home-text,
  .home-slider,
  .home-img {
    margin-left: 0;
    margin-right: 0;
    text-align: center; /* Centreer de tekst en afbeeldingen */
  }

  .overlay-text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .content {
    width: auto; /* Pas de breedte aan voor zeer kleine schermen */
    padding: 10px; /* Voeg wat padding toe voor kleine schermen */
  }

  .home-text h3,
  .home-text p {
    margin-left: 10px;
    margin-right: 10px;
  }

  .v-carousel {
    height: auto; /* Maak de carrouselhoogte auto */
  }
}

@media (max-width: 480px) {
  .overlay-text {
    font-size: 16px; /* Maak de tekst kleiner voor zeer kleine schermen */
  }
}
</style>
